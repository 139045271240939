import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import PageHeader from "../../../components/pageheader"
import DevSingle from "../../../components/devsingle"
import { devPageLinks } from "../../../utils/constants"
import { Button } from "../../../components/buttons"
import * as styles from "../../../styles/developments.module.css"
import {
  contactForm,
  formRow,
  withPrefix,
} from "../../../styles/contact.module.css"
import hero from "../../../images/nashville-hero.jpg"
import miami from "../../../images/dev-prev-miami.jpg"
import bellwood from "../../../images/dev-next-bellwood.jpg"

const DevelopmentsTypeMoreInfoPage = () => {
  return (
    <Layout isTestimonialsHidden activeMenuId={3}>
      <Seo title="Nashville" />
      <PageHeader
        title="Nashville"
        subTitle={`The future of Tropical Urban Living. Tropical modern aesthetic is on showcase at its finest at Nashville, a little piece of heaven where tropical dreams come true. Every residential unit flaunts a sense of spaciousness with stunning floor-to-ceiling windows to allow the inhabitants to "live in the light" as sunlight is in abundance and to enjoy flexiblie living space that can be adjusted to different lifestyles.`}
        image={hero}
        breadcrumbs={[
          { label: "Home", link: "/" },
          { label: "Developments / Residential", link: "/developments/type" },
          { label: "Nashville", link: null },
        ]}
      />
      <DevSingle
        title="Nashville"
        mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.374155651361!2d106.96324531536634!3d-6.345570163850703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699361d93e77b1%3A0xb791970fbe2dae25!2sCluster%20Nashville%20Kota%20Wisata%20Cibubur!5e0!3m2!1sen!2sid!4v1638513551605!5m2!1sen!2sid"
        activePage="Get more info"
        pageLinks={devPageLinks}
        prev={{
          link: "/developments/type/single",
          label: "Miami",
          bgImage: miami,
        }}
        next={{
          link: "/developments/type/single",
          label: "Bellwood",
          bgImage: bellwood,
        }}
      >
        <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">
          Get More Information
        </h2>
        <div className="mx-4 lg:mx-0 mb-8">
          <p>
            Kota Wisata will never sell or share your information with a third
            party
          </p>
        </div>
        <div className={`mx-4 lg:mx-0 ${styles.formWrap}`}>
          <form className={contactForm}>
            <div className={formRow}>
              <div>
                <input name="name" type="text" placeholder="Enter your name" />
              </div>
            </div>
            <div className={formRow}>
              <div>
                <input
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                />
              </div>
            </div>
            <div className={formRow}>
              <div>
                <div className={withPrefix}>
                  <span>+62</span>
                  <input name="phone" type="text" placeholder="812345xxxx" />
                </div>
              </div>
            </div>
            <div className={formRow}>
              <div className="text-right">
                <Button>Submit</Button>
              </div>
            </div>
          </form>
        </div>
      </DevSingle>
    </Layout>
  )
}

export default DevelopmentsTypeMoreInfoPage
